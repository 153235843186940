@font-face {
  font-family: "DongleRegular";
  src: local("DongleRegular"),
   url("./fonts/DongleRegular.woff") format("truetype");
}

body {
  margin: 0;
  font-family: 'DongleRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202124;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
